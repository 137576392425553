import "../../assets/css/modal.css";
import { MenuItem, Modal, Select, TextField } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NumericFormat } from "react-number-format";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "../common/Loading";
import { ReactComponent as AddCircle } from "../../assets/images/settings/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/images/settings/edit.svg";

const styles = {
  error: {
    "& div input": {
      borderColor: "red",
      borderLeft: "1px solid red !important"
    }
  }
};

const schema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  type: yup.string().required("Type is required!"),
  cost: yup.string().required("Value is required!")
});

const AddCustomExpensesModal = ({
  open,
  handleClose,
  editedExpenses,
  handleConfirm,
  isLoading
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      type: "fixed",
      cost: ""
    },
    resolver: yupResolver(schema)
  });

  const { cost, type } = watch();

  useEffect(() => {
    if (editedExpenses) {
      setValue("title", editedExpenses.title, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("type", editedExpenses.type, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("cost", editedExpenses.cost, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [editedExpenses]);

  const handleCurClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = (values) => handleConfirm(values, handleCurClose);

  const handlePriceChange = (v) =>
    setValue("cost", v, {
      shouldValidate: true,
      shouldDirty: true
    });

  const handleTypeChange = (e) =>
    setValue("type", e.target.value, {
      shouldValidate: true,
      shouldDirty: true
    });

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            {editedExpenses ? (
              <Edit className="confirm-modal-icon company" />
            ) : (
              <AddCircle className="confirm-modal-icon company" />
            )}
          </div>

          <div>{editedExpenses ? "Edit" : "Add"} Fixed Expense</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <label className={errors.title?.message && "error"}>Title*</label>
              <div className="input-box">
                <TextField
                  sx={errors.title?.message && styles.error}
                  fullWidth
                  type="text"
                  placeholder=" "
                  name="title"
                  required
                  {...register("title")}
                  error={touchedFields.title && !!errors.title?.message}
                  helperText={errors.title?.message}
                />
              </div>
            </div>

            <div className="form-item">
              <label>Type*</label>
              <div className="input-box expenses">
                <Select
                  className="payroll-table-select"
                  value={type}
                  onChange={handleTypeChange}
                  IconComponent={KeyboardArrowDown}>
                  <MenuItem value="fixed">Fixed</MenuItem>
                  <MenuItem value="per_employee">Per employee</MenuItem>
                </Select>
              </div>
            </div>

            <div className="form-item expenses">
              <div className="input-box expenses-modal-input-container">
                <div className="expenses-modal-input-before">$</div>
                <NumericFormat
                  onValueChange={(values) => handlePriceChange(values.floatValue)}
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={(event) => event.target.select()}
                  className="expenses-modal-input"
                  value={cost}
                  thousandSeparator
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 1000000 || [0, null, undefined].includes(floatValue);
                  }}
                />
              </div>
            </div>
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : editedExpenses ? "Save changes" : "Add Fixed Expense"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCustomExpensesModal;
