import { companyIcons } from "../../constants";
import axios from '../../axios';

const supportedApps = ["gusto", "paychex"];
const testModeApps = ["gusto"];

const CompanyCard = ({ cardData, handleConnect, isTestMode, isConnected }) => {
  const { name, description, key } = cardData;

  const isSupported = supportedApps.includes(key);
  const inTestMode = testModeApps.includes(key);

  const getButton = () => {
    let disabled = true;
    let text = "Coming soon";

    const showForEverybody = isSupported && !inTestMode;
    const showForTestMode = inTestMode && isTestMode;

    if (showForEverybody || showForTestMode) {
      disabled = false;
      text = "Connect now";
    }

    if (isConnected) {
      disabled = false;
      text = "Connected";
    }

    const handleDisconnect = () => {
      axios
      .get("/integrations/paychex/remove-paychex-integration")
      .then((response) => console.log(response))
    }

    return (
      <>
        <button
          onClick={handleConnect.bind(null, cardData.key)}
          className={`btn-style btn-outline small-btn ${
            text === 'Connect now' ? 'companies-btn connect-now' : ''
          }`}
          disabled={disabled}>
          {text}
        </button>
        {isConnected ? <button
          onClick={handleDisconnect}
          className={`btn-style btn-outline small-btn`}
          disabled={disabled}>
          Disconnect
        </button> : null
        }
      </>

    );
  };
  return (
    <li>
      <div className='logo-btn-sec'>
        <div className='app-logo'>
          <img src={companyIcons[key]} alt='' />
          <div className='app-text'>
            <h5>{name}</h5>
            <p>{description}</p>
          </div>
        </div>

        {getButton()}
      </div>
    </li>
  );
};

export default CompanyCard;
