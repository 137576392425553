import "../../../assets/css/modal.css";
import {
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ReactComponent as CloseIcon } from "../../../assets/images/close-icon.svg";
import Loading from "../../../components/common/Loading";
import { ReactComponent as AddCircle } from "../../../assets/images/settings/add-circle.svg";

const styles = {
  error: {
    "& div input": {
      borderColor: "#F04438 !important",
      borderLeft: "1px solid #F04438 !important"
    }
  }
};

const schema = yup.object().shape({
  name: yup.string().required("Coupon Name is required!"),
  id: yup.string().required("Coupon Code is required!")
});

const CouponModal = ({ open, handleClose, handleConfirm, isLoading }) => {
  const [isPercentage, setIsPercentage] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      id: "",
      amount_off: null,
      currency: null,
      duration_in_months: null,
      percent_off: 0,
      duration: "once"
    },
    resolver: yupResolver(schema)
  });

  const { duration = "once", amount_off, percent_off, duration_in_months } = watch();

  const handleCurClose = () => {
    handleClose();
    reset();
    setIsPercentage(true);
  };

  const onSubmit = (values) => {
    const dollarAmountOff = values.amount_off * 100

    const curValues = {
      name: values.name,
      id: values.id,
      duration: values.duration,
      ...(isPercentage
        ? { percent_off: values.percent_off }
        : { amount_off: dollarAmountOff, currency: values.currency }),
      ...(duration === "repeating" && { duration_in_months: values.duration_in_months })
    };

    handleConfirm(curValues, handleCurClose);
  };

  const handlePriceChange = (newValue) =>
    setValue(isPercentage ? "percent_off" : "amount_off", +newValue);

  const handleNumberOfMonthsChange = (newValue) => setValue("duration_in_months", newValue);

  const handleTypeChange = (event) => {
    if (event.target.value === "percent_off") {
      setIsPercentage(true);
      setValue("percent_off", 0);
      setValue("amount_off", null);
      setValue("currency", null);
    } else {
      setIsPercentage(false);
      setValue("percent_off", null);
      setValue("amount_off", 0);
      setValue("currency", "usd");
    }
  };

  useEffect(() => {
    if (duration === "repeating") {
      setValue("duration_in_months", 1);
    } else if (duration_in_months !== null) {
      setValue("duration_in_months", null);
    }
  }, [duration]);

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            <AddCircle className="confirm-modal-icon company" />
          </div>

          <div>Add Coupon</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <label className={errors.name?.message && "error"}>Coupon Name *</label>
              <div className="input-box admin-panel">
                <TextField
                  sx={errors.name?.message && styles.error}
                  fullWidth
                  type="text"
                  placeholder="Enter Coupon Name"
                  name="name"
                  required
                  {...register("name")}
                  error={touchedFields.name && !!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </div>
            </div>

            <div className="form-item">
              <label className={errors.id?.message && "error"}>Coupon Code *</label>
              <div className="input-box admin-panel">
                <TextField
                  sx={errors.id?.message && styles.error}
                  fullWidth
                  type="text"
                  placeholder="Enter Coupon Code"
                  name="id"
                  required
                  {...register("id")}
                  error={touchedFields.id && !!errors.id?.message}
                  helperText={errors.id?.message}
                />
              </div>
            </div>

            <div className="form-item percentage">
              <label>Type</label>
              <RadioGroup
                defaultValue="percent_off"
                value={isPercentage ? "percent_off" : "amount_off"}
                onChange={handleTypeChange}>
                <FormControlLabel
                  value="percent_off"
                  control={<Radio />}
                  label="Percentage discount"
                />
                <FormControlLabel
                  value="amount_off"
                  control={<Radio />}
                  label="Fix amount discount"
                />
              </RadioGroup>
            </div>

            <div className="form-item">
              <label>{isPercentage ? "Percent off" : "Amount off"} *</label>
              <div className="input-box expenses-modal-input-container">
                <div className="expenses-modal-input-before">{isPercentage ? "%" : "$"}</div>
                <NumericFormat
                  onValueChange={(values) => {
                    handlePriceChange(values.floatValue || 0);
                  }}
                  className="expenses-modal-input"
                  value={isPercentage ? percent_off : amount_off}
                  thousandSeparator
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      (!isPercentage && floatValue < 10000) ||
                      (isPercentage && floatValue <= 100) ||
                      [0, null, undefined].includes(floatValue)
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-item">
              <label className={errors.duration?.message && "error"}>Duration *</label>
              <div className="input-box admin-panel">
                <Select
                  sx={errors.duration?.message ? styles.errorSelect : {}}
                  required
                  className="employees-select admin-panel"
                  fullWidth
                  value={duration}
                  name="duration"
                  {...register("duration")}
                  IconComponent={KeyboardArrowDown}>
                  <MenuItem value="forever">Forever</MenuItem>
                  <MenuItem value="once">Once</MenuItem>
                  <MenuItem value="repeating">Multiple months</MenuItem>
                </Select>
                {!!errors.duration?.message && (
                  <FormHelperText
                    component="div"
                    error={!!errors.duration?.message}
                    sx={styles.selectErrorMessage}>
                    {errors.duration?.message}
                  </FormHelperText>
                )}
              </div>
            </div>

            {duration === "repeating" && (
              <div className="form-item">
                <label>Number of months *</label>
                <div className="input-box expenses-modal-input-container">
                  <NumericFormat
                    onValueChange={(values) => {
                      handleNumberOfMonthsChange(values.floatValue || 1);
                    }}
                    value={duration_in_months}
                    thousandSeparator
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue > 0 && floatValue <= 31;
                    }}
                  />
                </div>
              </div>
            )}
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CouponModal;
