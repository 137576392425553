import SearchBig from "../../assets/images/search-big.png";

const FilterBy = ({ title, filterBy, handleFilterByChange, searchQuery, handleQueryChange }) => {
  return (
      <div className="subtop-title-bar">
        <h4>{title}</h4>
        <div className="search-box" style={{ marginLeft: "19rem", width: "33%" }}>
          <img src={SearchBig} alt=""/>
          <input
              type="text"
              placeholder="Employee Search"
              value={searchQuery}
              onChange={handleQueryChange}
          />
        </div>
        <ul>
          <li>
            <button
                className={`revenue-average ${filterBy === 4 ? "active" : ""}`}
                onClick={handleFilterByChange.bind(null, 4)}>
              Last 4 Payrolls
            </button>
          </li>
          <li>
            <button
                className={`${filterBy === 1 ? "active" : ""}`}
                onClick={handleFilterByChange.bind(null, 1)}>
              Last Payroll
            </button>
          </li>
        </ul>
      </div>
  );
};

export default FilterBy;
