import "../../assets/css/modal.css";
import { Modal } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "../common/Loading";
import { ReactComponent as Edit } from "../../assets/images/settings/edit.svg";

const schema = yup.object().shape({
  value: yup.string().required("This field is required!")
});

const EditFixedExpensesItem = ({ open, handleClose, editedItem, handleConfirm, isLoading }) => {
  const { handleSubmit, setValue, reset, watch } = useForm({
    mode: "all",
    defaultValues: {
      value: "0.00"
    },
    resolver: yupResolver(schema)
  });

  const { value } = watch();

  useEffect(() => {
    if (editedItem?.value) {
      setValue("value", editedItem.value, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [editedItem]);

  const handleCurClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = (values) => handleConfirm(values.value, handleCurClose);

  const handlePriceChange = (v) =>
    setValue("value", v, {
      shouldValidate: true,
      shouldDirty: true
    });

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            <Edit className="confirm-modal-icon company" />
          </div>

          <div>Edit {editedItem?.name}</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <div className="input-box expenses-modal-input-container">
                <div className="expenses-modal-input-before">
                  {editedItem?.isPercentage ? "%" : "$"}
                </div>
                <NumericFormat
                  onValueChange={(values) => handlePriceChange(values.floatValue)}
                  fixedDecimalScale
                  decimalScale={2}
                  onFocus={(event) => event.target.select()}
                  className="expenses-modal-input"
                  value={value}
                  thousandSeparator
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 1000000 || [0, null, undefined].includes(floatValue);
                  }}
                />
              </div>
            </div>
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
              className="confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : "Save changes"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditFixedExpensesItem;
